.flex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

}

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 60px 10px 60px;
}