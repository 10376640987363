.first-group,
.second-group {
  padding: 20px;
}
.second-group {
  background-color: rgba(191, 191, 191, 0.15);
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  & > *:not(:last-child) {
    margin-right: 1rem !important;
  }
}
.headerBlock {
  display: flex;
  margin: 20px;
  margin-left: 40px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  & > *:not(:last-child) {
    margin-right: 1.5rem;
  }
}
.titleContainer {
  display: flex;
  align-items: center;
}

.image-container {
  overflow: hidden;
  border-radius: 50%;
  height: 134px;
  width: 134px;
  margin: 0 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  .user-image {
    width: 100%;
    height: 100%;
  }
  .text-avatar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 3.125rem;
    color: #ffffff;
    justify-content: center;
  }
}
